import CircularProgress from '@mui/material/CircularProgress';
import React, { useState,useEffect } from 'react';
import Cookies from 'js-cookie';
import "./finance.css"
import moment from 'moment';
import { address } from '../settings';


const Finance = (props) => {
    const [loading,setLoading] = useState(true)
    const [users,setUsers] = useState([])
    const [foreignUsers,setForeignUsers] = useState([])
    const [preLoading,setPreloading] = useState(true)
    const token = Cookies.get('_auth')
    const [rate, setRate] = useState({
        USD: {
            "ID": "R01235",
            "NumCode": "840",
            "CharCode": "USD",
            "Nominal": 1,
            "Name": "Доллар США",
            "Value": 0,
            "Previous": 88.0206
        }
    });
    const updateData = () => {
        let foreign_users = [...foreignUsers]
        foreign_users = foreign_users.filter((item)=>item.login!=="").map((item)=>{
            let newItem = { ...item };
            try{
                if (item.login === "Mexico") {
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (rate.USD.Value * newItem.research_count).toFixed(2)
                }
                if (item.login === "Iraq") {
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (rate.USD.Value*newItem.research_count).toFixed(2)
                }
                if (item.login === "India") {
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (rate.USD.Value * newItem.research_count).toFixed(2)
                }
                if (item.login === "Burkina-Faso") {
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (rate.USD.Value*newItem.research_count).toFixed(2)
                }
            }
            catch(error){
                console.log(error.message)
            }
            return newItem
        })
        const FtotalResearchCount = foreign_users.map(item => item.research_count).reduce((a, b) => a + b, 0);
        const FtotalSum = foreign_users.map(item => parseFloat(item.sum)).reduce((a, b) => a + b, 0).toFixed(2);

        foreign_users.push({
            full_name: "",
            id: 1001,
            login: "",
            organization: "Итого",
            price: '',
            research_count: FtotalResearchCount,
            tarif_type: "",
            sum: FtotalSum
        });
        setForeignUsers(foreign_users)
    }
    const setInfo = (users, date) => {
        let ruzaevkaDiff = Math.ceil(moment(date.end_date).diff(moment('2023-07-01 00:00:00'), 'months', true));
        const exactMonthsDiff = moment(date.end_date).diff(moment(date.start_date), 'months', true);
        const roundedUpMonthsDiff = Math.ceil(exactMonthsDiff);
        // Фильтрация пользователей по логину
        let new_users = users.filter(item => {
            const requiredLogins = [
                'eris',
                'Ruzaevka',
                'eris_oms_flg',
                'eris_oms_rg',
                'pac0510@bugulma.ru',
                'MosMed',
                'moscow',
                'MosMed-hub',
                'eris_exp_flg'
            ];
        
            // Проверка на наличие логина в списке
            if (!requiredLogins.includes(item.login)) {
                return false;
            }
        
            // Для пользователя 'eris' проверка суммарного значения словаря research_count
            if (item.login === 'eris') {
                const researchCountValues = Object.values(item.research_count);
                const totalResearchCount = researchCountValues.reduce((a, b) => a + b, 0);
                return totalResearchCount > 0;
            }
        
            // Для остальных пользователей проверка на research_count > 0
            return item.research_count > 0;
        });
        let foreign_users = users.filter((user)=>{
            const requiredLogins = [
                'Mexico',
                'Iraq',
            ];
            return requiredLogins.includes(user.login)
        })
        console.log(foreign_users)
        foreign_users.push({
            datetime:null,
            full_name:"Индия",
            id:1014,
            login:"India",
            organization:"Индия",
            price:0,
            research_count:0,
            tarif_type:"free"
        })
        foreign_users.push({
            datetime:null,
            full_name:"Буркина-Фасо",
            id:1015,
            login:"Burkina-Faso",
            organization:"Буркина-Фасо",
            price:0,
            research_count:0,
            tarif_type:"free"
        })
        foreign_users = foreign_users.map((item)=>{
            let newItem = { ...item };
            try {
                if (item.login === "Mexico") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 1;
                    newItem.valute = 'USD'
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (rate.USD.Value * newItem.research_count).toFixed(2)
                }
                if (item.login === "Iraq") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 1;
                    newItem.valute = 'USD'
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (0).toFixed(2)
                }
                if (item.login === "India") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 1;
                    newItem.valute = 'USD'
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (rate.USD.Value * newItem.research_count).toFixed(2)
                }
                if (item.login === "Burkina-Faso") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 1;
                    newItem.valute = 'USD'
                    newItem.cbr = (rate.USD.Value).toFixed(2)
                    newItem.sum = (rate.USD.Value * newItem.research_count).toFixed(2)
                }
            }
            catch {
                if (item.login === "Mexico") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 1;
                    newItem.valute = 'USD'
                    newItem.cbr = 88
                    newItem.sum = 88 * newItem.research_count
                }
                if (item.login === "Iraq") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 1;
                    newItem.valute = 'USD'
                    newItem.cbr = 88
                    newItem.sum = (88*newItem.research_count).toFixed(2)
                }
                if (item.login === "India") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 0;
                    newItem.valute = 'USD'
                    newItem.cbr = 88
                    newItem.sum = 88 * newItem.research_count
                }
                if (item.login === "Burkina-Faso") {
                    newItem.tarif_type = 'Поштучный';
                    newItem.valprice = 0;
                    newItem.valute = 'USD'
                    newItem.cbr = 88
                    newItem.sum = (88*newItem.research_count).toFixed(2)
                }
            }
            return newItem
        })
        const FtotalResearchCount = foreign_users.map(item => item.research_count).reduce((a, b) => a + b, 0);
        const FtotalSum = foreign_users.map(item => parseFloat(item.sum)).reduce((a, b) => a + b, 0).toFixed(1);

        foreign_users.push({
            full_name: "",
            id: 1001,
            login: "",
            organization: "Итого",
            price: '',
            research_count: FtotalResearchCount,
            tarif_type: "",
            sum: FtotalSum
        });
        setForeignUsers(foreign_users)
    
        // Обновление информации о пользователях
        new_users = new_users.map(item => {
            let newItem = { ...item }; // Создаем новый объект, чтобы не мутировать исходный
            
            if (item.login === "Ruzaevka") {
                if(roundedUpMonthsDiff>0){
                    if (roundedUpMonthsDiff <= ruzaevkaDiff) {
                        newItem.sum = parseInt(18000 * roundedUpMonthsDiff);
                    } else {
                        newItem.sum = parseInt(18000 * ruzaevkaDiff);
                    }
                    newItem.tarif_type = 'Пакетный (500 исследований)';
                    newItem.price = 36;
                }
            }
            if (item.login === 'Lugansk') {
                let sum = 0;
                const may2024 = moment('2024-05-01 00:00:00');
                const endDate = moment(date.end_date);
                const startDate = moment(date.start_date);
            
                if (endDate.isAfter(may2024)) {
                    if (startDate.isBefore(may2024)) {
                        const monthsSinceMay2024 = endDate.diff(may2024, 'months', true);
                        sum = parseInt(18000 * Math.ceil(monthsSinceMay2024));
                    } else {
                        const monthsSinceStart = endDate.diff(startDate, 'months', true);
                        sum = parseInt(18000 * Math.ceil(monthsSinceStart));
                    }
                }
                newItem.sum = sum > 0 ? sum : 0;
                newItem.tarif_type = 'Пакетный (500 исследований)';
                newItem.price = 36
            }

            if (item.login === 'moscow') {
                let sum = 0;
                const may2024 = moment('2024-08-01 00:00:00');
                const endDate = moment(date.end_date);
                const startDate = moment(date.start_date);
            
                if (endDate.isAfter(may2024)) {
                    if (startDate.isBefore(may2024)) {
                        const monthsSinceMay2024 = endDate.diff(may2024, 'months', true);
                        sum = parseInt(41666 * Math.ceil(monthsSinceMay2024));
                    } else {
                        const monthsSinceStart = endDate.diff(startDate, 'months', true);
                        sum = parseInt(41666 * Math.ceil(monthsSinceStart));
                    }
                }
                newItem.sum = sum > 0 ? sum : 0;
                newItem.tarif_type = 'Пакетный (1 666 исследований)';
                newItem.price = 25
            }

            if (item.login === 'eris') {
                const researchCountValues = Object.values(item.research_count);
                const researchCountKeys = Object.keys(item.research_count);
                let prices = [];
                let sum = researchCountKeys.reduce((total, key, index) => {
                    let price = 0;
                    if (key == 0) {
                        price = 48;
                    } else if (key == 1) {
                        price = 37;
                    } else if (key == 2) {
                        price = 6.9;
                    }
                    prices.push(price); // Добавляем цену в массив
                    return total + researchCountValues[index] * price;
                }, 0).toFixed(1);
            
                let priceString = prices.join(' / ');
            
                newItem.research_count = researchCountValues.reduce((a, b) => a + b, 0);
                newItem.tarif_type = 'Поштучный';
                newItem.price = priceString; // Используем строку с ценами
                newItem.sum = sum;
            }
    
            if (item.login === "Tumen") {
                newItem.sum = (item.research_count * 23.5).toFixed(1);
                newItem.tarif_type = 'Поштучный';
                newItem.price = 23.5;
            }
    
            if (item.login === 'eris_oms_flg'||item.login === 'MosMed'||item.login === 'MosMed-hub'||item.login === 'eris_exp_flg' ) {
                newItem.sum = (item.research_count * 6.9).toFixed(1);
                newItem.tarif_type = 'Поштучный';
                newItem.price = item.price / 10;
            }
    
            if (item.login === 'eris_oms_rg') {
                newItem.sum = (item.research_count * 8.8).toFixed(1);
                newItem.tarif_type = 'Поштучный';
                newItem.price = item.price / 10;
            }
    
            if (item.login === 'pac0510@bugulma.ru') {
                newItem.sum = parseInt(item.research_count * 48);
                newItem.tarif_type = 'Поштучный';
            }
            return newItem;
        });
    
        const totalResearchCount = new_users.map(item => item.research_count).reduce((a, b) => a + b, 0);
        const totalSum = new_users.map(item => parseFloat(item.sum)).reduce((a, b) => a + b, 0).toFixed(2);
    
        new_users.push({
            full_name: "",
            id: 1000,
            login: "",
            organization: "Итого",
            price: '',
            research_count: totalResearchCount,
            tarif_type: "",
            sum: totalSum
        });
    
        setUsers(new_users);
    }
    const getData = () =>{
        if(!moment(document.getElementById('start').value).isValid()){alert('Указана несуществующая дата начала периода');return 1;}
        if(!moment(document.getElementById('end').value).isValid()){alert('Указана несуществующая дата конца периода');return 1;}
        if(document.getElementById('start').value===''&&document.getElementById('end').value===''){alert('Задайте конкретный период');return 1;} 
        if(document.getElementById('start').value!==''&&document.getElementById('end').value==='')document.getElementById('end').value= moment().format('YYYY-MM-DD')
        if(document.getElementById('start').value===''&&document.getElementById('end').value!=='')document.getElementById('start').value=moment(document.getElementById('end').value).date(1).format('YYYY-MM-DD')
        // if(document.getElementById('start').value>=document.getElementById('end').value)return alert("Неверно указан период (конец периода должен быть больше начала)")
        let body={
            start_date:moment(document.getElementById('start').value).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end_date:moment(document.getElementById('end').value).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }
        setLoading(true)
        fetch(address+'/users/for/finance', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'accept': 'application/json',
                'Access-Control-Allow-Origin':'*',
                'Authorization': 'Bearer '+token,
            },
            body:JSON.stringify(body)
            }).then(response=>{
                if(response.ok) return response.json();
                else return false}).then(res=>{
                if(res!==false){
                    setInfo(res,body)
                    setLoading(false)
                }
            })
    }
    useEffect(()=>{
        updateData()
    },[users])
    useEffect(() => {
        const fetchData = async () => {
            // Установка начальных значений даты
            document.getElementById('end').value = moment().format('YYYY-MM-DD');
            document.getElementById('start').value = moment().date(1).format('YYYY-MM-DD');

            const body = {
                start_date: moment().startOf("month").format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            };

            let fetchFlag = 'success'; // Флаг для определения успешности первого запроса

            // Первый запрос: получение курса валют
            fetch("https://www.cbr-xml-daily.ru/daily_json.js")
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Не удалось получить данные от Центробанка России');
                    }
                    return response.json();
                })
                .then(data1 => {
                    setRate(data1.Valute);
                })
                .catch(error => {
                    console.error(error.message);
                    setRate({
                        USD: {
                            "ID": "R01235",
                            "NumCode": "840",
                            "CharCode": "USD",
                            "Nominal": 1,
                            "Name": "Доллар США",
                            "Value": 88,
                            "Previous": 88.0206
                        }
                    });
                    fetchFlag = 'error'; // Устанавливаем флаг ошибки
                })
                .finally(() => {
                    // Второй запрос: получение списка пользователей
                    fetch(address + '/users/for/finance', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                            'accept': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': 'Bearer ' + token,
                        },
                        body: JSON.stringify({ ...body, fetchFlag }) // Передаем флаг в тело запроса
                    })
                        .then(response2 => {
                            if (!response2.ok) {
                                throw new Error('Не удалось получить данные пользователей');
                            }
                            return response2.json();
                        })
                        .then(res => {
                            setInfo(res, body);
                        })
                        .catch(error => {
                            console.error(error.message);
                        })
                        .finally(() => {
                            setLoading(false);
                            setPreloading(false);
                        });
                });
        };

        fetchData();
    }, [token, address]);
    return (
        <div className='admin-finance'>
            <div className='admin-finance-table'>
                <div className='admin-finance-table-title'>
                    <div className='admin-stat-table-title-text'>Клиенты со сдельной и пакетной системой оплаты</div>
                </div>
                <div className='admin-finance-table-form'>
                    <input className="finance-input" id='start' type="date"/>
                    <input className="finance-input" id='end' type="date"/>
                    <button className='finance-button' onClick={() => getData()}>Отобразить</button>
                </div>
                <div className='admin-finance-table-data'>
                    {loading ? (
                        <div className='div-loading'>
                            <CircularProgress sx={{color: 'white'}}/>
                        </div>
                    ) : (
                        <div className='stat-table-data' id='finance-table-div'>
                            <table className='ad-stat-table' id='finance-table'>
                                <thead className='ad-stat-thead' id='finance-thead'>
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th' id='stat-tr-th'>Организация</th>
                                        <th className='stat-tr-th'>Кратко</th>
                                        <th className='stat-tr-th'>Стоимость исследования, руб.</th>
                                        <th className='stat-tr-th'>Тариф</th>
                                        <th className='stat-tr-th'>Исследований за период, шт</th>
                                        <th className='stat-tr-th'>Сумма за период, руб.</th>
                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody' id='finance-tbody ad-stat-tbody'>
                                {users.length === 0 && (
                                    <tr className='ad-stat-tr'>
                                        <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                    </tr>
                                )}
                                {users.map((item) => {
                                        return item.full_name === '' ?
                                            <tr key={item.id} className='ad-stat-tr'>
                                                <td className='stat-tr-td' colSpan={4}>{item.organization}</td>
                                                <td className='stat-tr-td'>{item.research_count}</td>
                                                <td className='stat-tr-td'>{item.sum}</td>
                                            </tr>
                                            :
                                            <tr key={item.id} className='ad-stat-tr'>
                                                <td className='stat-tr-td'>{item.organization}</td>
                                                <td className='stat-tr-td'>{item.full_name}{item.login === 'MosMed-hub' && ' *'}</td>
                                                <td className='stat-tr-td'>{item.price}</td>
                                                <td className='stat-tr-td'>{item.tarif_type}</td>
                                                <td className='stat-tr-td'>{item.research_count}</td>
                                                <td className='stat-tr-td'>{item.sum}</td>
                                            </tr>
                                    }
                                )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                {!loading &&
                    <div className='admin-finance-table-note'>
                        <div className='admin-stat-table-note-text'>* : Реутов</div>
                    </div>}
            </div>
            <div className='admin-finance-table-2'>
                <div className='admin-finance-table-title'>
                <div className='admin-stat-table-title-text'>Иностранные клиенты</div>
                </div>
                <div className='admin-finance-table-data' >
                    {loading ? (
                        <div className='div-loading'>
                            <CircularProgress sx={{color: 'white'}}/>
                        </div>
                    ) : (
                        <div className='stat-table-data' id='finance-table-div'>
                            <table className='ad-stat-table' id='finance-table'>
                                <thead className='ad-stat-thead' id='finance-thead'>
                                    <tr className='ad-stat-tr'>
                                        <th className='stat-tr-th' id='stat-tr-th'>Организация</th>
                                        <th className='stat-tr-th'>Кратко</th>
                                        <th className='stat-tr-th'>Валюта</th>
                                        <th className='stat-tr-th'>Стоимость в валюте</th>
                                        <th className='stat-tr-th'>Курс по ЦБ, руб.</th>
                                        <th className='stat-tr-th'>Исследований за период, шт</th>
                                        <th className='stat-tr-th'>Сумма за период, руб.</th>
                                    </tr>
                                </thead>
                                <tbody className='ad-stat-tbody' id='ad-stat-tbody'>
                                {foreignUsers.length === 0 && (
                                    <tr className='ad-stat-tr'>
                                        <td className='stat-tr-td' colSpan='3'>Нет пользователей</td>
                                    </tr>
                                )}
                                {foreignUsers.map((item) => {
                                        return item.full_name === '' ?
                                            <tr key={item.id} className='ad-stat-tr'>
                                                <td className='stat-tr-td' colSpan={5}>{item.organization}</td>
                                                <td className='stat-tr-td'>{item.research_count}</td>
                                                <td className='stat-tr-td'>{item.sum}</td>
                                            </tr>
                                            :
                                            <tr key={item.id} className='ad-stat-tr'>
                                                <td className='stat-tr-td'>{item.organization}</td>
                                                <td className='stat-tr-td'>{item.full_name}</td>
                                                <td className='stat-tr-td'>{item.valute}</td>
                                                <td className='stat-tr-td'>{item.valprice}</td>
                                                <td className='stat-tr-td'>{item.cbr}</td>
                                                <td className='stat-tr-td'>{item.research_count}</td>
                                                <td className='stat-tr-td'>{item.sum}</td>
                                            </tr>
                                    }
                                )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            <div className='admin-finance-table-1'>
                <div className='admin-finance-table-title'>
                    <div className='admin-stat-table-title-text'>Тендеры</div>
                </div>
                <div className='admin-finance-table-data'>
                    {preLoading ? (
                        <div className='div-loading'>
                            <CircularProgress sx={{color: 'white'}}/>
                        </div>
                    ) : (
                        <div className='stat-table-data' id='finance-table-div'>
                            <table className='ad-stat-table' id='finance-table'>
                                <thead className='ad-stat-thead' id='finance-thead'>
                                <tr className='ad-stat-tr'>
                                    <th className='stat-tr-th' id='stat-tr-th'>Организация</th>
                                    <th className='stat-tr-th'>Кратко</th>
                                    <th className='stat-tr-th'>Тех. поддержка</th>
                                    <th className='stat-tr-th'>Стоимость контракта, руб.</th>
                                    <th className='stat-tr-th'>Стоимость поддержки, руб./квартал</th>
                                </tr>
                                </thead>
                                <tbody className='ad-stat-tbody' id='ad-stat-tbody'>
                                <tr className='ad-stat-tr'>
                                    <td className='stat-tr-td'>Министерство здравоохранения Курской области</td>
                                    <td className='stat-tr-td'>Курск</td>
                                    <td className='stat-tr-td'>Ежеквартальная оплата</td>
                                    <td className='stat-tr-td'>2 505 000</td>
                                    <td className='stat-tr-td'>400 000</td>
                                </tr>
                                <tr className='ad-stat-tr'>
                                    <td className='stat-tr-td'>ГБУЗ "Волгоградский Областной Медицинский
                                        Информационно-Аналитический Центр"
                                    </td>
                                    <td className='stat-tr-td'>Волгоград</td>
                                    <td className='stat-tr-td'>Гарантийное обсуживание (1 год)</td>
                                    <td className='stat-tr-td'>6 666 000</td>
                                    <td className='stat-tr-td'>0</td>
                                </tr>
                                <tr className='ad-stat-tr'>
                                    <td className='stat-tr-td'>Медицинский Информационно-Аналитический Центр Республики
                                        Бурятия
                                    </td>
                                    <td className='stat-tr-td'>Бурятия</td>
                                    <td className='stat-tr-td'>Нет данных</td>
                                    <td className='stat-tr-td'>3 000 000</td>
                                    <td className='stat-tr-td'>0</td>
                                </tr>
                                <tr className='ad-stat-tr'>
                                    <td className='stat-tr-td' colSpan={3}>Итого</td>
                                    <td className='stat-tr-td'>12 171 000</td>
                                    <td className='stat-tr-td'>400 000</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>)}
                </div>
            </div>
        </div>
    );
}

export default Finance;